<script setup lang="ts">
import { icons } from "@ui/FeIcon/FeIcon.vue";
import { themes } from "@ui/New/ButtonConfig";
import FeButtonNew from "@ui/New/FeButton.vue";

interface Props {
    modelValue: number,
    step?: number,
    maxLimit?: number,
    minLimit?: number,
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: 0,
    step: 1,
    maxLimit: Infinity,
    minLimit: 0,
});

const emit = defineEmits([ "update:modelValue" ]);

function increase() {
    const newValue = props.modelValue + props.step;

    if (newValue <= props.maxLimit) {
        emit("update:modelValue", newValue);
    }
}

function decrease() {
    const newValue = props.modelValue - props.step;

    if (newValue >= props.minLimit) {
        emit("update:modelValue", newValue);
    }
}
</script>

<template>
    <div class="fe-counter d-flex align-items-center">
        <FeButtonNew
            :theme="themes.TertiarySmall"
            :icon="icons.fill.minus"
            :disabled="modelValue === minLimit"
            class="fe-counter__btn text--color--alt"
            @click="decrease"
        />

        <p class="fe-counter__value padding-xxs d-flex align-items-center justify-content-center font__caption-2--bold">
            {{ modelValue }}
        </p>

        <FeButtonNew
            :theme="themes.TertiarySmall"
            :icon="icons.fill.plus"
            :disabled="modelValue === maxLimit"
            class="fe-counter__btn text--color--alt"
            @click="increase"
        />
    </div>
</template>

<style scoped lang="scss">
.fe-counter {
    padding: 0.25rem;
    background-color: var(--color-tertiary-4);
    border-radius: 2.5rem;

    &__value {
        width: 2rem;
        height: 2rem;
    }
}
</style>
