import { getFeatureFlagConfig } from "@front/core/modules/FeatureFlagConfiguration/helpers/localStorage";

const featureFlags = {
    enableTechWork: false,


    disableWWW: true,
    enablePromoBannerInEnterButton: false,
    enableConpoints: false,
    enableABReg: false,
    enableMysticJackpots: false,
    enableNewUIComponents: true,
};

export default {
    ...featureFlags,
    ...getFeatureFlagConfig(),
};
