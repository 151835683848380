<template>
    <div
        class="carousel-scroll__pagination"
    >
        <!-- TODO: убрать 'penultimate-active' после Santa Vegas       -->
        <div class="carousel-scroll__pagination-container" role="tablist">
            <button
                v-for="(_, index) in length"
                :key="index"
                class="carousel-scroll__pagination-dot"
                aria-hidden="false"
                role="tab"
                :value="index"
                :aria-label="index"
                :class="{
                    'carousel-scroll__pagination-dot--active': index === activeIndex,
                    'penultimate-active': activeIndex === length - 1 && index === length - 2
                }"
                @click="changeActivePage(index)"
            />
        </div>
    </div>
</template>


<script>
export default {
    name: "Pagination",

    props: {
        length: {
            type: Number,
            required: true,
        },
        activeIndex: {
            type: Number,
            required: true,
        },
    },
    emits: [ "change" ],
    methods: {
        changeActivePage(index) {
            this.$emit("change", index);
        },
    },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>
