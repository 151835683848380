<template>
    <div class="n-fe-button-wrapper" :class="{'full-width': shouldDisplayFullWidth , 'full-width-mobile': fullWidthMobile}">
        <button
            class="n-fe-button"
            :class="classList"
            :type="buttonType"
            :disabled="disabled || waiting"
            @click="clickHandler"
        >
            <FeIcon v-if="icon && !iconLeft" class="fe-button__icon" :icon="icon"/>
            <div v-if="theme.color === colorsList.slab" class="fe-button__slab-illustration">
                <FeIcon v-if="slabIcon" class="fe-button__slab-icon" :icon="slabIcon"/>
                <FeImage v-if="slabImage" class="fe-button__slab-image" :src="slabImage"/>
            </div>
            <span v-if="$slots.default" class="label"><slot/></span>
            <span v-if="$slots.right" class="fe-button__right"><slot name="right"/></span>
            <span v-if="$slots.caption && shouldDisplayCaption" ref="captionRef" class="caption"><slot name="caption"/></span>
        </button>
        <FeLoader
            v-if="waiting"
            class="loader"
        />
    </div>
</template>

<script setup lang="ts">
import FeIcon from "@ui/FeIcon/FeIcon.vue";
import FeImage from "@ui/FeImage/FeImage.vue";
import { themes, sizeShadowList, sizeList } from "@ui/New/ButtonConfig.ts";
import type { IButtonTheme } from "@ui/New/ButtonConfig.ts";
import { computed } from "vue";
import { ref, useAttrs, useSlots } from "vue";
import FeLoader from "@ui/FeLoader/FeLoader.vue";
import { colorsList } from "@ui/lib";

interface Props {
    theme?: IButtonTheme,
    disabled?: boolean;
    waiting?: boolean;
    fullWidth?: boolean;
    fullWidthMobile?: boolean,
    uppercase?: boolean,
    icon?: boolean | string,
    textAlign?: string,
    shadowSize: string,
    shadow: boolean,
    submit: boolean,
    iconLeft: boolean,
    slabIcon?: string,
    slabImage?: string,
    initialCase?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    theme: themes.PrimaryMedium,
    disabled: false,
    waiting: false,
    fullWidth: false,
    fullWidthMobile: false,
    uppercase: false,
    icon: false,
    textAlign: "",
    shadowSize: sizeShadowList.small,
    shadow: false,
    submit: false,
    iconLeft: false,
    initialCase: false,
});

const attrs = useAttrs();
const slots = useSlots();

// slots & refs reactivity doesn't work in Storybook except default one
const storyBookCaption = computed(() => attrs.caption);
const hasStoryBookCaption = computed(() => storyBookCaption.value !== undefined && storyBookCaption.value !== "");

const emit = defineEmits([ "click" ]);

const captionRef = ref(null);

const hasCaption = computed(() => hasStoryBookCaption.value || captionRef.value?.textContent);

const shouldDisplayCaption = computed(() =>
    hasCaption.value &&
    !props.icon &&
    props.theme.size !== sizeList.small &&
    props.theme.color === colorsList.primary,
);

const buttonType = computed(() => props.submit ? "submit" : "button");

const shouldDisplayFullWidth = computed(() => !props.icon && props.fullWidth);

const classList = computed(() => ({
    [props.theme.color]: true,
    [props.theme.size]: true,
    "disabled": props.disabled,
    "loading": props.waiting,
    "captioned": shouldDisplayCaption.value,
    "full-width": shouldDisplayFullWidth.value,
    "n-fe-button--initial-case": props.initialCase,

    [`n-fe-button--text-${props.textAlign}`]: props.textAlign,
    [`n-fe-button--shadow-${props.shadowSize}`]: props.shadowSize && props.shadow,
    "n-fe-button--uppercase": props.uppercase,
    "icon": props.icon && !slots.default,
    "n-fe-button--full-width-mobile": props.fullWidthMobile,
}));

function clickHandler($event) {
    if (props.disabled) {
        return;
    }
    emit("click", $event);
}
</script>

<style scoped lang="scss">
@import "@ui/index.scss";

.n-fe-button-wrapper {
    position: relative;
    width: max-content;

    &.full-width {
        width: 100%;
    }

    &.full-width-mobile {
        width: 100%;
        @include media(M) {
            width: max-content;
        }
    }
}

.n-fe-button {
    position: relative;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    border: none;
    background: var(--color-primary-gradient);
    cursor: pointer;
    transition: none;
    box-sizing: border-box;
    text-transform: lowercase;

    &::first-letter {
        text-transform: uppercase;
    }

    &.full-width {
        width: 100%;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--text-left {
        text-align: left;
    }

    &--text-right {
        text-align: right;
    }

    &--full-width {
        width: 100%;
        justify-content: center;
    }

    &--initial-case {
        text-transform: initial;
        &:first-letter {
            text-transform: initial;
        }
    }

    &.icon {
        border-radius: 50%;
        padding: 0.5rem;
        background: var(--color-primary-gradient);
        color: var(--color-layer-body);

        &.loading {
            .fe-button__icon {
                opacity: 0;
            }
        }

        &.disabled {
            .fe-button__icon {
                cursor: not-allowed;
            }
        }
    }

    &__right {
        margin-left: .5rem;
    }

    &--full-width-mobile {
        width: 100%;
        justify-content: center;

        @include media(M) {
            width: auto;
        }
    }

    &.captioned {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 2rem;
        border-radius: 1rem;

        .label {
            @include font-Button-Large;
        }

        .caption {
            @include font-Button-Caption;
            color: var(--color-layer-body);
        }
    }

    &:not(.disabled, .loading):hover {
        background: var(--color-primary-3);
    }

    &:not(.disabled, .loading):active {
        background: var(--color-primary-4);
    }

    &.disabled, &.loading {
        @include tint-element;
    }

    &.disabled {
        cursor: not-allowed;
    }

    &.loading {
        cursor: wait;
    }

    .label {
        @include font-size--Body($font-weight--bold);
        color: var(--color-layer-body);
    }

    &.large {
        .label {
            @include font-Button-Large;
        }

        &.captioned {
            padding: 0.5rem 1.5rem;
            border-radius: 1rem;

            .label {
                @include font-Button-Large($caption: true);
            }

            .caption {
                @include font-Button-Caption;
            }
        }

        & + .loader {
            min-height: 2rem;
            min-width: 2rem;
        }

        &.icon {
            width: 3rem;
            height: 3rem;
            padding: 0.75rem;

            .fe-button__icon {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }

    &.medium {
        .label {
            @include font-Button-Medium;
        }

        &.captioned {
            padding: 0.5rem 1.5rem;
            border-radius: 1rem;

            .label {
                @include font-Button-Medium($caption: true);
            }

            .caption {
                @include font-Button-Caption;
            }
        }

        & + .loader {
            min-height: 2rem;
            min-width: 2rem;
        }

        &.icon {
            width: 2.5rem;
            height: 2.5rem;

            .fe-button__icon {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        &.icon + .loader {
            min-height: 1.5rem;
            min-width: 1.5rem;
            height: 1.5rem;
            width: 1.5rem;
        }

        &.slab {
            background: var(--color-tertiary-4);
            backdrop-filter: blur(5px);
            border: 2px solid var(--color-tertiary-3);
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 6rem;
            border-radius: 1rem;
            padding: 0.5rem;

            .label {
                color: var(--color-tertiary-1);
                text-transform: uppercase;
                @include font-size--SmallCaption($font-weight--bold);
            }
            .fe-button__slab-illustration {
                height: 1.5rem;
                margin-bottom: 0.25rem;
            }

            .fe-button__slab-icon {
                color: var(--color-tertiary-1);
                font-size: 1.5rem;
            }

            &:not(.disabled, .loading):hover {
                cursor: pointer;
                background: var(--color-tertiary-3);
                border: 2px solid transparent;
            }

            &:not(.disabled, .loading):active {
                background: var(--color-tertiary-4);
                border: 2px solid transparent;
                cursor: pointer;
            }

            &.disabled {
                @include tint-element;

                .fe-button__slab-icon {
                    cursor: not-allowed;
                }
            }

            &.loading {
                @include tint-element;

                .fe-button__slab-icon {
                    cursor: wait;
                }
            }
        }
    }

    &.small {
        &:not(.icon) {
            padding: 0.5rem 1rem;
        }

        .label {
            @include font-Button-Small;
        }

        & + .loader {
            min-height: 1.5rem;
            min-width: 1.5rem;
            height: 1.5rem;
            width: 1.5rem;
        }

        &.icon {
            width: 2rem;
            height: 2rem;

            .fe-button__icon {
                width: 1rem;
                height: 1rem;
            }
        }
    }

    &.secondary {
        position: relative;
        background: var(--color-tertiary-4);
        backdrop-filter: blur(5px);
        z-index: 1;

        &:not(.icon) {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: inherit;
                padding: 2px;
                z-index: -1;

                @include border-gradient;
            }
        }

        &:not(.disabled, .loading):hover {
            background: var(--color-tertiary-4);

            &::before {
                background: var(--color-primary-3);
            }
        }

        &:not(.disabled, .loading):active {
            background: var(--color-tertiary-4);

            &::before {
                background: var(--color-primary-4);
            }
        }

        &.disabled, &.loading {
            background: var(--color-tertiary-4);

            @include tint-element;

            &::before {
                background: var(--color-primary-gradient);
            }
        }

        .label {
            color: var(--color-tertiary-1);
        }

        &.icon {
            background: var(--color-tertiary-3);

            .fe-button__icon {
                color: var(--color-tertiary-1);
            }

            &:not(.disabled, .loading):active {
                background: var(--color-tertiary-3);
            }
        }
    }

    &.tertiary {
        background: var(--color-tertiary-3);
        backdrop-filter: blur(5px);

        &:not(.disabled, .loading):hover {
            background: var(--color-tertiary-4);
        }

        &:not(.disabled, .loading):active {
            background: var(--color-tertiary-3);
        }

        &.disabled, &.loading {
            backdrop-filter: none;
            @include tint-element;
        }

        .label {
            color: var(--color-primary-3);
        }

        &.icon {
            background: transparent;

            .fe-button__icon {
                color: var(--color-tertiary-1);
            }
        }
    }
}

.loader {
    position: absolute;
    height: 2rem;
    width: 2rem;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    cursor: wait;
}
</style>
